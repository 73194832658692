<template>
	<div class="wrapper transformer">

		<!-- 查询 -->
		<el-form :inline="true" :model="searchform">
			<el-form-item label="用户姓名">
				<el-input v-model="searchform.name" clearable placeholder="请输入用户姓名"></el-input>
			</el-form-item>
			<el-form-item label="手机号">
				<el-input v-model="searchform.phone" clearable placeholder="请输入手机号"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="search">查询</el-button>
				<el-button type="primary" @click="operation('add')">新增</el-button>
				<el-button type="primary" @click="copyAccount()">复制账号</el-button>
			</el-form-item>
		</el-form>

		<!-- 列表 -->
		<el-table :data="tableData" border stripe style="width: 100%">
			<el-table-column prop="username" label="用户姓名" align="center">
			</el-table-column>
			<el-table-column prop="useraccount" label="登录账号" align="center">
			</el-table-column>
			<el-table-column prop="phone" label="手机号" align="center">
			</el-table-column>
			<el-table-column label="用户类型" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.otheritem == 0">管理员</span>
					<span v-if="scope.row.otheritem == 1">社区</span>
					<span v-if="scope.row.otheritem == 2">第三方平台</span>
				</template>
			</el-table-column>
			<el-table-column prop="rolename" label="部门名称" align="center">
			</el-table-column>
			<el-table-column prop="areaname" label="所属社区" align="center">
			</el-table-column>
			<el-table-column label="操作" fixed="right" width="360" align="center">
				<template slot-scope="scope">
					<el-button @click="operation('update',scope.row)" type="primary">修改</el-button>
					<el-button @click="deleteUser(scope.row.sysuserid)" type="danger">删除</el-button>
					<el-button @click="allotmenu(scope.row.sysuserid)" type="warning">权限分配</el-button>
					<el-button type="info" @click="showLogList(scope.row.sysuserid)" plain>登录记录</el-button>
				</template>
			</el-table-column>
		</el-table>


		<div class="paginationbox">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum">
			</el-pagination>
		</div>

		<!-- 用户新增/编辑 -->
		<el-dialog :title="operationType =='add'? '新增用户':'修改用户'" width='800px' :visible.sync="userDialogVisible" :before-close="onBeforeClose" :close-on-click-modal="false">
			<el-form ref="userForm" :rules="rules" :model="userForm" label-width="130px">
				<el-form-item label="用户姓名"  prop="username">
					<el-input v-model="userForm.username"></el-input>
				</el-form-item>
				<el-form-item label="登录账号" prop="useraccount">
					<el-input v-model="userForm.useraccount"></el-input>
				</el-form-item>
				<el-form-item v-if="operationType == 'add'" label="密码" prop="password">
					<el-input v-model="userForm.password" type="password" onpaste="return false"></el-input>
				</el-form-item>
				<el-form-item v-show="operationType == 'update'" label="新密码">
					<el-input v-model="userForm.newpassword" :type="passwordType" onpaste="return false">
						<i
							class="el-icon-view el-input__icon"
							:style="passwordType=='text'?'color: #318fdf':''"
							slot="suffix"
							@click="handleIconClick">
						</i>
					</el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="phone">
					<el-input v-model="userForm.phone"></el-input>
				</el-form-item>
				<el-form-item label="用户类型" prop="otheritem">
					<el-radio-group v-model="userForm.otheritem" @change="userTypeChange">
						<el-radio v-for="item in userType" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
					</el-radio-group>
				</el-form-item>

				<el-form-item label="选择部门" prop="rolecode" v-if="userForm.otheritem == 2">
					<el-select v-model="userForm.rolecode"   value-key="rolecode" clearable filterable placeholder="请选择">
						<el-option
							v-for="item in departments"
							:key="item.rolecode"
							:label="item.rolename"
							:value="item.rolecode">
						</el-option>
					</el-select>
				</el-form-item>


				<el-form-item label="所属社区" v-if="userForm.otheritem == 0 || userForm.otheritem ==1 || userForm.otheritem ==2">
					<el-select v-model="userForm.arealevel" @change="selectChange" multiple value-key="uuid" filterable clearable placeholder="请选择">
						<el-option
							v-for="item in communitys"
							:key="item.levelcode"
							:label="item.name"
							:value="item.levelcode">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="submitForm('userForm')">提交</el-button>
					<el-button @click="resetForm('userForm')">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>


		<!-- 复制账号 -->
		<el-dialog title="复制账号" width='800px' :visible.sync="copyAccountDialogVisible" :before-close="copyAccountOnBeforeClose" :close-on-click-modal="false">
			<el-form ref="accountForm" :rules="accountRules" :model="accountForm" label-width="130px">
				<el-form-item label="用户姓名"  prop="username">
					<el-input v-model="accountForm.username"></el-input>
				</el-form-item>
				<el-form-item label="登录账号" prop="useraccount">
					<el-input v-model="accountForm.useraccount"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="password">
					<el-input v-model="accountForm.password" type="password" onpaste="return false"></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="phone">
					<el-input v-model="accountForm.phone"></el-input>
				</el-form-item>
				<el-form-item label="复制对象" prop="copyUserName" class="pointer">
					<el-input v-model="accountForm.copyUserName" @focus="gotoChooseCopyObj" readonly></el-input>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="copyAccountSubmitForm('accountForm')">提交</el-button>
					<el-button @click="copyAccountResetForm('accountForm')">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<el-dialog title="选择复制对象" :visible.sync="dialogFormVisible">
			<user-list ref="accounts" @getCopyUserId="getCopyUserId"></user-list>
		</el-dialog>


		<el-dialog v-if="logListDialogFormVisible" title="登录记录" :visible.sync="logListDialogFormVisible">
			<log-list :logUserId="logUserId" @getCopyUserId="getCopyUserId"></log-list>
		</el-dialog>

		<!-- 权限分配 -->
		<el-dialog title="权限分配" width='50%' :visible.sync="quanxianVisible" :before-close="handleClose3" :close-on-click-modal="false">
			<div class="loudong" v-loading="loading">
				<el-tree
					class="tree"
					:data="menusData"
					show-checkbox
					default-expand-all
					node-key="menucode"
					:props="defaultProps"
					ref="tree"
					>
				</el-tree>
				<div class="btns">
					<el-button type="primary" size="small" @click="onSubmit3">分配</el-button>
					<el-button size="small" @click="quanxianVisible = false">取消</el-button>
				</div>
			</div>
		</el-dialog>
		
	</div>

</template>

<script>
import dict from '@/utils/dictionary'
import userList from './userList.vue'
import logList from './logList.vue'

import { getUsers, getAllMenus, addUser, copyUser, updateUser, delUser,addUserMenu, getUserArea, getDepartments} from '@/api/system'
	export default {
		data() {
			let validateNewpass = (rule, value, callback) => {
				// let reg = /^[a-zA-z](?=.*\d)(?=.*[a-zA-z])(?=.*[^a-zA-Z0-9]).{7}$/;
				let reg = /^(?![A-Za-z0-9]+$)(?![A-Za-z\W]+$)(?![0-9\W]+$)[a-zA-Z][a-zA-Z0-9\W]{7,}$/
        if (!reg.test(value)) {
          callback(new Error('请输入正确格式的密码（以字母开头，包含字母、数字和特殊字符的8位数及以上的密码）'));
        } else {
          callback();
        }
      };
			let validateArealevel = (rule, value, callback) => {
				if (value.length == 0) {
          callback(new Error('请选择所属社区'));
        } else {
          callback();
        }
			};
			return {
				menusData: [],
        defaultProps: {
          children: 'childrenmenu',
          label: 'menuname'
        },
				passwordType: 'password',
				operationType: 'add',
				userType: dict.userType,
				searchform: {
					name: '',
					phone: '',
				},
				tableData: [],
				userDialogVisible: false,
				copyAccountDialogVisible: false,
				dialogFormVisible: false,
				logListDialogFormVisible: false,
				logUserId: '',
				userForm: {
					sysuserid:'',
					username:'',
					useraccount: '',
					phone:'',
					password:'',
					newpassword:'',
					arealevel: [],
					rolecode:'',
					otheritem: null
				},
				accountForm:{
					copyUserName:'',
					copyUserId: '',
					username:'',
					useraccount: '',
					phone:'',
					password:''
				},
				currentPage: 1,
				fenye: {
					totalnum: 0,
					pagesizes: [10, 20, 30, 40, 50],
					pagesize: 10
				},

				list: [],
				quanxianVisible:false,
				checkList:[],
				loading:true,
				sysuserid:'',
				dianji:'',
				polices: [],
				departments: [],
				communitys:[],
				accountRules:{
					username: [
            { required: true, message: '请输入用户用户姓名', trigger: 'blur' }
          ],
          useraccount: [
            { required: true, message: '请输入登录账号', trigger: 'blur' }
          ],
          phone: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
						{ min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' }
          ],
					copyUserName: [
            { required: true, message: '请选择复制对象', trigger: 'change' }
          ]
				},
				rules: {
          username: [
            { required: true, message: '请输入用户用户姓名', trigger: 'blur' }
          ],
          useraccount: [
            { required: true, message: '请输入登录账号', trigger: 'blur' }
          ],
          phone: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
						{ min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' }
          ],
					newpassword: [
						{ validator: validateNewpass, trigger: 'blur' }
					],
          arealevel: [
            { validator: validateArealevel, trigger: 'blur' }
          ],
          otheritem: [
            { required: true, message: '请选择用户类型', trigger: 'blur' }
          ],
          rolecode: [
            { required: true, message: '请选择部门', trigger: 'blur' }
          ]
        },
			}
		},
		components: {
			userList,
			logList
		},
		mounted() {
			this.getList();
			// this.getUserCommunity();
		},
		methods: {
			//查看密码明文
			handleIconClick(){
				if(this.passwordType == 'password'){
					this.passwordType = 'text'
				}else{
					this.passwordType = 'password'
				}
			},
			selectChange(val){
				if(this.userForm.otheritem == 1 && val.length>1){
					this.userForm.arealevel = [];
					this.$message.warning('只能选择一个社区')
				}
				this.$forceUpdate();
			},
			//改变用户类型
			userTypeChange(type){
				// this.userForm.otheritem = type;
				this.userForm.arealevel = [];
				this.$forceUpdate();
				if(type != 2){
					this.userForm.rolecode = '';
				}
				this.getUserCommunity();

				//第三方平台
				if(type == 2){
					//获取第三方部门名称
					getDepartments({
						userid: this.GLOBAL.adminId()
					}).then(res=>{
						if (res.result == 200) {
								this.departments = res.detail;
							} else {
								this.$message.error(res.description);
							}
					})
				}
				
			},
			//编辑或新增用户编辑框			
			operation(type, row){
				this.operationType = type;
				this.userForm.arealevel = [];
				if(type == 'update'){					
					this.getUserCommunity();
					this.userForm = Object.assign({}, row);
					let newareacode = row.areacode.split(',');
					this.userForm.arealevel = newareacode;
					//用户类型为第三方
					if(row.otheritem == 2){
						if(row.rolecode){
							this.userForm.rolecode = parseInt(row.rolecode);
						}
						//获取第三方部门名称
						getDepartments({
							userid: this.GLOBAL.adminId()
						}).then(res=>{
							if (res.result == 200) {
									this.departments = res.detail;
								} else {
									this.$message.error(res.description);
								}
						})
					}
					this.userForm.otheritem = parseInt(row.otheritem);
				}

				
				// console.log(this.userForm)
				this.userDialogVisible = true;
			},
			
			//关闭用户编辑框
			onBeforeClose(done) {
				//重置表单
				this.userForm = {
					sysuserid:'',
					username:'',
					useraccount: '',
					phone:'',
					password:'',
					newpassword:'',
					arealevel: [],
					rolecode:'',
					otheritem: null
				}
				done();
      	this.$refs['userForm'].resetFields();
				this.passwordType = 'password'
			},
			getCopyUserId(row){
				console.log(row)
				this.accountForm.copyUserName = row.username;
				this.accountForm.copyUserId = row.sysuserid;
				this.dialogFormVisible = false;
			},
			copyAccount(){
				this.copyAccountDialogVisible = true;
			},
			showLogList(id){
				console.log(id)
				this.logListDialogFormVisible = true;
				this.logUserId = id;
			},
			gotoChooseCopyObj(){
				this.dialogFormVisible = true;
			},
			//关闭复制账号编辑框
			copyAccountOnBeforeClose(done){
				this.accountForm = {
					username:'',
					useraccount: '',
					phone:'',
					password:'',
					copyUserid:'',
					copyUserName: ''
				}
				done();
      	this.$refs['accountForm'].resetFields();
			},
			//列表查询
			search() {		
				this.currentPage = 1;		
				this.getList();
			},
			//获取所有区域供新增用户选择
			getUserCommunity(){
				var that = this;
				getUserArea({
						userid: that.GLOBAL.adminId(),
				}).then(res=>{
					// console.log('getUserCommunity', res.detail)
					that.communitys = res.detail;
				})
			},
			
			//切换页面条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.fenye.pagesize = val;
				this.currentPage = 1;
				this.getList();
			},
			//切换分页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			//删除用户账号
			deleteUser(id) {
				var that = this;
				that.$confirm('是否确认删除该用户?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					cancelButtonClass: "btn-custom-cancel",
					type: 'warning'
				}).then(() => {
					delUser({
						userid: that.GLOBAL.adminId(),
						sysuserid: id
					}).then(res=>{
						if (res.result == 200) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
							that.currentPage = 1;
							that.getList();
						} else {
							that.$message.error(res.description);
						}
					})

				}).catch(() => {
					
				});
			},
			//给用户分配菜单
			allotmenu(id) {
				var that = this;
				that.checkList = [];
				that.loading = true;
				
				that.quanxianVisible = true;
				that.sysuserid = id;

				getAllMenus({
					userid: that.GLOBAL.adminId(),
					sysuserid: id
				}).then(res=>{
					that.loading = false;
					
					if (res.result == 200) {
						
						//全部菜单
						this.menusData = res.detail.sysmenu;
						//当前用户已选择菜单
						let userMenu = res.detail.usermenu;
						let selectEd = [];
						this.getChooseKeyId(userMenu,selectEd)
						
					} else {
						that.$message.error(res.description);
					}
				})
			},
			//获取选中的 menucode
			getChooseKeyId(nodes, ids){
				nodes.map(item=>{
					if(item.childrenmenu){
						this.getChooseKeyId(item.childrenmenu, ids)
					}else{
						ids.push(item.menucode);	//没有下级菜单的菜单才能选中
					}
				})

				this.checkList = ids;
				// console.log(ids)
				// console.log(this.checkList)
				this.$refs.tree.setCheckedKeys(this.checkList)

			},
			//获取用户列表
			getList() {
				getUsers({
						userid: this.GLOBAL.adminId(),
						page: this.currentPage,
						pagesize: this.fenye.pagesize,
						name: this.searchform.name,
						phone: this.searchform.phone
				}).then(res=>{
					if(res.result == 200){
						let detail = res.detail;
						let list = detail.list;
						this.fenye.totalnum = detail.totalRow;
						this.tableData = list;
					} else {
						this.$message.error(res.description);
					}
				})
			},
			//确认复制账号
			copyAccountSubmitForm(formName){
				// console.log(this.operationType,'提交')
        this.$refs[formName].validate((valid) => {
          	if (valid) {
							let data = {
								userid: this.GLOBAL.adminId(),
								username: this.accountForm.username,
								phone: this.accountForm.phone,
								password: this.accountForm.password,
								useraccount: this.accountForm.useraccount,
								copyUserid: this.accountForm.copyUserId
							}
							copyUser(data).then(res=>{
								if (res.result == 200) {
									this.$message({
										message: '新增成功',
										type: 'success'
									});
									this.resetForm('accountForm');
									this.copyAccountDialogVisible = false;
									this.currentPage = 1;
									this.getList();
								} else {
									this.$message.error(res.description);
								}
							})
						} else {
							return false;
						}
        });
			},
			//重置复制账号表单
			copyAccountResetForm(formName) {
				this.userDialogVisible = false;
				this.$refs[formName].resetFields();
			},
			//重置表单
			resetForm(formName) {
				this.userDialogVisible = false;
				this.$refs[formName].resetFields();
			},
			//确认提交
			submitForm(formName) {
				
				// console.log(this.operationType,'提交')
        this.$refs[formName].validate((valid) => {
          	if (valid) {
							
							if(this.userForm.arealevel.length == 0){
								this.$message.warning('请选择所属社区')
								return false;
							}
							let data = {
								userid: this.GLOBAL.adminId(),
								sysuserid: this.operationType=='add'?null:this.userForm.sysuserid,
								username: this.userForm.username,
								phone: this.userForm.phone,
								password: this.userForm.password,
								useraccount: this.userForm.useraccount,
								arealevel: this.userForm.arealevel.join(','),
								otheritem: this.userForm.otheritem,
								rolecode: this.userForm.rolecode
							}
							//新增
							if(this.operationType == 'add'){
								addUser(data).then(res=>{
									if (res.result == 200) {
										this.$message({
											message: '新增成功',
											type: 'success'
										});
										this.resetForm('userForm');
										// this.userDialogVisible = false;
										this.currentPage = 1;
										this.getList();
									} else {
										this.$message.error(res.description);
									}
								})
							}
							//编辑
							if(this.operationType == 'update'){
								//新密码
								let newpassword = this.userForm.newpassword;
								// console.log(newpassword)
								if(newpassword){
									let reg = /^(?![A-Za-z0-9]+$)(?![A-Za-z\W]+$)(?![0-9\W]+$)[a-zA-Z][a-zA-Z0-9\W]{7,}$/
									if (!reg.test(newpassword)) {
										this.$message.warning('请输入正确格式的密码（以字母开头，包含字母、数字和特殊字符的8位数及以上的密码）');
										return false;
									}else{
										data.password = newpassword;
									}
								}
								if(!newpassword){
									data.password = '';
								}
								updateUser(data).then(res=>{
									if (res.result == 200) {
										this.$message({
											message: '修改成功',
											type: 'success'
										});
										this.resetForm('userForm');
										// this.userDialogVisible = false;
										this.currentPage = 1;
										this.getList();
									} else {
										this.$message.error(res.description);
									}
								})
							}
						} else {
							// console.log('error submit!!');
							return false;
						}
        });
      },
			//确认菜单权限
			onSubmit3() {
				var that = this;
				if(that.checkList == []){
					that.$message({
						message: '请选择菜单权限',
						type: 'warning'
					});
					return;
				}
				 let list = [];

				let chooseList = this.$refs.tree.getCheckedNodes();
				// console.log(chooseList)
				//最多支持5级菜单，冒号之前的是父级菜单的menucode,后面是子级菜单的menucode
				chooseList.map(item=>{
					let menucode = item.menucode;
					if(menucode.length == 6){
						list.push(menucode.substring(0,4) + ':' + menucode);
					}else if(menucode.length == 8){
						list.push(menucode.substring(0,6) + ':' + menucode);
						list.push(menucode.substring(0,4) + ':' + menucode.substring(0,6));
					}else if(menucode.length == 10){
						list.push(menucode.substring(0,8) + ':' + menucode);
						list.push(menucode.substring(0,6) + ':' + menucode.substring(0,8));
						list.push(menucode.substring(0,4) + ':' + menucode.substring(0,6));
					}else if(menucode.length == 12){
						list.push(menucode.substring(0,10) + ':' + menucode);
						list.push(menucode.substring(0,8) + ':' + menucode.substring(0,10));
						list.push(menucode.substring(0,6) + ':' + menucode.substring(0,8));
						list.push(menucode.substring(0,4) + ':' + menucode.substring(0,6));
					}else{
						list.push(menucode + ':');
					}
					
				})

				addUserMenu({
						userid: that.GLOBAL.adminId(),
						sysuserid: that.sysuserid,
						menuid:list.join(',')
				}).then(res=>{
					if (res.result == 200) {
						that.$message({
							message: '操作成功',
							type: 'success'
						});
						that.quanxianVisible = false;
					} else {
						that.$message.error(res.description);
					}
				})
			},
			handleClose3: function(done) {
				done();
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">

.el-select{
	width: 100%;
}
.paginationbox{
	text-align: right;
	margin-top: 20px;
}
	


	.el-textarea .el-textarea__inner {
		resize: none;
	}

	.loudong{
		.tree{
			height: 600px;
			overflow: auto;
		}
		.btns{
			margin-top: 20px;
			text-align: center;
		}
	}

	.pointer ::v-deep .el-input__inner{
		cursor: pointer !important;
	}
</style>
